import React from "react";
import PropTypes from "prop-types";
import { System } from "../../commons/Constants";
import { FooterCashParking } from "./FooterCashParking";
import { FooterParkWeb } from "./FooterParkWeb";

export const Footer = ({ landerConfig, queryConfig }) => {
  if (landerConfig.system === System.PARKWEB && !landerConfig.turnKeyReseller) {
    return (
      <FooterParkWeb
        copyrightTextId="parkwebcopyrightText"
        landerConfig={landerConfig}
        parkwebDisclaimerText="parkwebDisclaimerText"
      />
    );
  }
  return (
    <FooterCashParking
      copyrightTextId="cashparkingcopyrightText"
      landerConfig={landerConfig}
      queryConfig={queryConfig}
    />
  );
};

Footer.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};
