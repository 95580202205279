import React from "react";
import { AdSense, getAdSensePageType } from "./utils/adSenseUtils";
import { ValidTemplates } from "../commons/constants/ValidTemplates";
import SimpleLayout from "./layout/SimpleLayout";
import Logger from "../commons/Logger";
import { ErrorCode, EventType, QueryParam } from "../commons/Constants";
import { Settings } from "../config";
import PropTypes from "prop-types";
import { NoAdsPage } from "./NoAdsPage";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";
import { getContent } from "./content/Content";
import HegLayout from "./layout/HegLayout";

// Using ES6 object destructuring to locally scope the variables without having to use props.landerConfig or props.queryConfig
// eslint-disable-next-line complexity
export default function AdSensePage({ landerConfig, queryConfig }) {
  // check if pubId and DRID are present. Proceed with CAF request regardless of DRID status
  const adSense = landerConfig.adSense;

  if (!adSense.drid || !adSense.pubId) {
    Logger.error("pubid or drid is missing");
    const errorCode = adSense.drid
      ? ErrorCode.ERROR_NO_PUBID
      : ErrorCode.ERROR_NO_DRID;
    sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig);

    // No ads but can render TDFS and other stuff
    return <NoAdsPage landerConfig={landerConfig} queryConfig={queryConfig} />;
  }

  if (!ValidTemplates.includes(landerConfig.lander.template)) {
    // This should not happen, we select a DEFAULT template while building landerConfig.
    Logger.warn("Unknown template " + landerConfig.lander.template);
  }
  if (landerConfig.lander.template === AdSense.template.PARK_WEB_HEG) {
    return (
      <HegLayout
        page={getAdSensePageType(queryConfig)}
        landerConfig={landerConfig}
        queryConfig={queryConfig}
        content={getContent(landerConfig.lander.template, landerConfig.system)}
      />
    );
  }
  return (
    <SimpleLayout
      page={getAdSensePageType(queryConfig)}
      landerConfig={landerConfig}
      queryConfig={queryConfig}
      content={getContent(landerConfig.lander.template, landerConfig.system)}
    />
  );
}

AdSensePage.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};

// Error event if lander-param content is invalid (missing pubId, drid, etc.)
function sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig) {
  const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
  event.errorCode = errorCode;
  event.sToken = queryConfig[QueryParam.S_TOKEN];
  postEvent(Settings.EVENT_PUBLISH_API, event);
}
