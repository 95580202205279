import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import GdLogo from "./GDLogo";
import { BannerType } from "./Banner";

const GD_LOGO_WIDTH = 120;

// eslint-disable-next-line complexity
const getBannerCopy = (domainName, isDomainExpired, bannerType) => {
  let mainTextId = "Banner-Domain-IsRegistered-MaybeForSale";
  let subTextId = "Banner-Domain-IsParkedFree";
  if (isDomainExpired && bannerType === BannerType.AUCTION) {
    mainTextId = "Banner-Domain-IsExpired-IsAuctioned";
    subTextId = null;
  } else if (isDomainExpired && bannerType === BannerType.DBS) {
    mainTextId = "Banner-Domain-IsExpired";
  } else if (!isDomainExpired && bannerType === BannerType.AUCTION) {
    mainTextId = "Banner-Domain-IsAuctioned";
    subTextId = null;
  } else if (isDomainExpired && bannerType === BannerType.BACKORDER) {
    mainTextId = "Banner-Domain-IsExpired";
    subTextId = "Banner-Domain-BackOrder";
  } else if (!isDomainExpired && bannerType === BannerType.BACKORDER) {
    mainTextId = "Banner-Domain-Interested";
    subTextId = "Banner-Domain-BackOrder";
  }
  return (
    <>
      <b>
        <FormattedMessage
          id={mainTextId}
          values={{
            domain: domainName,
          }}
        />
      </b>
      {subTextId && (
        <>
          &nbsp;{" "}
          <FormattedMessage
            id={subTextId}
            values={{
              domain: domainName,
            }}
          />
        </>
      )}
    </>
  );
};

const GDBanner = ({ landerConfig, banner, bannerClick }) => {
  const domainName = landerConfig.domain.rootDomain;
  const isDomainExpired = (landerConfig.domain || {}).expired;
  const bannerCopy = getBannerCopy(domainName, isDomainExpired, banner.type);

  return (
    <div data-testid="tdfsBannerExperiment" className="pw-banner">
      <GdLogo
        className="pw-banner-logo"
        width={GD_LOGO_WIDTH}
        color="#61EDEA"
      />
      <p className="pw-banner-text">{bannerCopy}</p>
      <a href={banner.link} onClick={() => bannerClick(banner.type)}>
        <button className="pw-banner-cta">
          <FormattedMessage
            id="Banner-Button-Text"
            values={{
              domain: domainName,
            }}
          />
        </button>
      </a>
    </div>
  );
};

GDBanner.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  domainName: PropTypes.string,
  bannerClick: PropTypes.func,
  banner: PropTypes.shape({
    link: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default GDBanner;
